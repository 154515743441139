.ModalGraficoRelatorio .modal-dialog{
    width: 70%;
}

.legenda {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 40px;
}

.legenda p {
    font-size: 12px;
    font-weight: bold;
}

#icon {
    font-size: 25px;
    margin-right: 5px;
    color: #6A9AD5;
}
modal-body{
    margin: 0;
}

#gerarPDFGrafico{
    margin: 0;
    color: black;
    background-color: white;
}

#dadosEdicao{
    text-align: center;
}


#logo{
    width: 200;
    height: 100px;
}

#cabecalho{
    margin-bottom: 5px;
}

#cabecalho p {
    font-size: 14px;
    margin: 0;
}

#inside {
    display: flex;
    flex-direction: row;
}

#inside p:nth-of-type(1) {
    margin-left: 15px;
    font-weight: bold;
}

#inside p:nth-of-type(2) {
    margin-left: 5px;
} 

#cabecalhoTexto {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#cabecalhoTexto p:nth-of-type(1){
    font-size: 20px;
    margin-top: 2%;
    margin-right: 25%;
    font-weight: bold;
    color: black;
}

/* Ocultar/Mostrar campo Observações ao gerar pdf */
/* @media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
} */
