h1, p {}

.bg-awesome {
  background-color: orange;
}

.bg-more-awesome {
  background-color: yellowgreen;
}

.bg-awesomer {
  background-color: red;
}

.bg-awesomest {
  background-color: brown;
}

.bg-most-awesome {
  background-color: turquoise;
}

.bg-luis {
  background-color: #444;
}

.bg-roscan {
  background-color: #b66;
}

.bg-victor {
  background-color: #ccc;
}


.progress {
  margin-bottom: 0px !important;
}
.progress-bar{
  color: black

}



.bg-etapa-1{
  background-color: #FFECCC;
}
.bg-etapa-2{
  background-color: #F9F871;
}
.bg-etapa-3{
  background-color: #FFBA6A;
}
.bg-etapa-4{
  background-color: #B38AAE;
}
.bg-etapa-5{
  background-color: #FF8982;
}
.bg-etapa-6{
  background-color: #E08976;
}
.bg-etapa-7{
  background-color: #827567;
}
.bg-etapa-8{
  background-color: #A46877;
}
.bg-etapa-9{
  background-color: #C25450;
}
.bg-etapa-10{
  background-color: #634F5F;
}
.bg-etapa-11{
  background-color: #5C3D71;
}
.bg-etapa-12{
  background-color: #783155 ;
}
.bg-etapa-empty{
  background-color: #C5D2D2;
}