.modal-grafico-relatorio .modal-dialog {
  width: 95%;
}

.modal-grafico-relatorio .modal-header {
  border: 0px !important;
  background: #333e42;
}

.modal-body{
  padding: 15px;
}

.modal-title{
  padding-left: 1%;
  border-radius: 3px;
}
.content-header {
  text-align: left !important;
  display: flex !important;
  flex-direction: column !important;
  font-size: 1em !important;
  width: 22% !important;
  border-right: 3px solid #6b6b6b;
  padding-left: 1em;
}

.content-bar-compare {
  width: 80%;
}

.content-checkbox {
  width: 5%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.content-material {
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-label-section {
  flex: 1;
  display: flex;
  justify-content: center;
  font-size: 14px !important;
  font-weight: bold !important;
  /* color: #003975 !important; */
}

.labels_header {
  display: flex;
  font-size: 20px !important;
  font-weight: bold !important;
  margin-top: 0.8em!important;
}

.labels_title {
  font-weight: bold !important;
  /* color: #003975 !important; */
}

.labels_setores {
  font-weight: bold !important;
  /* color: #003975 !important; */
}

.modal-report .modal-header {
  border-bottom: 0 !important;
}

.tab-line {
  border-top: 1px solid #e5e5e5;
  margin: 0;
  width: 100%;
}

.tab-box {
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
}

.tab-option-disabled {
  width: 200px;
  color: #e5e5e5;
  height: 25px;
  cursor: pointer;
  margin: 0px;
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
}


/* hover feio */
/* .tab-option-active .tab-option-disabled,
:hover {
  color: #003975;
} */

.tab-option-active {
  width: 200px;
  height: 25px;
  cursor: pointer;
  margin: 0px;
  text-align: center;
  /* color: #003975; */
  border-bottom: 1px solid #333;
}

.labels_header p {
  font-size: 20px !important;
  margin-left: 2%;
}

.linha
{
  border: 1px solid #6b6b6b ;
  width: 99.86%;
  margin-left: 0.84%;
}

.linhas
{
  border: 1px solid #6b6b6b ;
  width: 100%;
}

.logoRosc img 
{
  max-width: 100%;
  filter: opacity(0.3);
}

.legend{
  display: grid;
  justify-content: center;
  max-width: 100%;
}