#settings {
    position: absolute;
    width: 100%;
    bottom: 0;
    margin-bottom: 40%;
    /* left: 0; */
}

#clients {
    position: absolute;
    width: 100%;
    bottom: 0;
    margin-bottom: 60%;
    /* /* left: 0; */
}
.form-control{
    background: transparent!important;
    border: 1px solid #888888!important;
    color: #f0f0f0
}
.control-label{
font-size: 12px;
    margin-bottom: 5px;
    text-transform: uppercase;
}
.form-control:disabled{
    background-color: #6c6c6c!important;
}
.form-control:hover, .form-control:focus{
    color: #f0f0f0
}
body{
    color: #f0f0f0!important;
    background-color: #414141;
}
.modal-content{
    background-color: #414141;
}
.navbar-default .navbar-brand{
    color: #f0f0f0
}
.table-bordered>tbody>tr>td, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>td, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>thead>tr>th{
    border: 1px solid #888888;
}
.table-bordered{
    border: 1px solid #888888;
    border-radius: 10px;
}
.close{
    color: #f0f0f0
    ;
    opacity: 1;
}
.btn {
    color: #f0f0f0;
}

.checkbox input[type="checkbox"]:checked + label::after {
    color: #f0f0f0; 

}
a{
    color: #f0f0f0
}

/* pagination  */
.pagination>.active>a{

    background-color: rgba(197, 21, 47, 0.70);
    border: 1px solid #5a6c74;
}
.pagination>li>a, .pagination>li>span{
    background-color: #364044;
    border: 1px solid #5a6c74;
    color: #f0f0f0;
}
.pagination>li>a:hover{
    background-color: #5a6c74;
    border: 1px solid #5a6c74;
    color: #f0f0f0;


}

.apexcharts-menu{

    background: #364044!important;
    border: 1px solid #888888!important;
}
.apexcharts-menu-item:hover{

    background: #5C5C5C!important;
}
.apexcharts-yaxis-label tspan{
    color: #f0f0f0!important
}
.card .title{

    color:#f0f0f0
}