#break{
    word-break: break-word;
    /* height: 100px; */
}

.modalRecuperacao .modal-dialog{
    width: 60%;
}

/*Altera a div que está no cabeçalho*/
.title h3 input
{
    width: 15%;
    text-align: left;
    background-color: transparent;
    color: white;
    border: none;
}


.content .col-md-3
{
    margin-right: 3%;
    width: 30%;
    padding-right: 1px;
}
